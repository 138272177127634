var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseCard',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showModalReprovar),callback:function ($$v) {_vm.showModalReprovar=$$v},expression:"showModalReprovar"}},[_c('v-card',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',[_vm._v(" Confirmação ")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"observacao","rules":"required"}},[_c('v-text-field',{attrs:{"label":"Motivo"},model:{value:(_vm.observacao),callback:function ($$v) {_vm.observacao=$$v},expression:"observacao"}})],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[(_vm.canUpdate)?_c('v-btn',{attrs:{"color":"error","disabled":invalid},on:{"click":_vm.confirmReprovar}},[_vm._v("Reprovar")]):_vm._e()],1)]}}])})],1)],1),_c('v-card',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-card-actions',{staticClass:"justify-end"},[_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"rules":{
              required: true,
              min_value: 1,
              max_value: _vm.solicitacoes.length,
            },"name":"quantidade"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [(_vm.canUpdate)?_c('v-text-field',{attrs:{"label":"Quantidade","type":"number","min":1,"max":_vm.solicitacoes.length,"error-messages":errors},on:{"input":_vm.selecionarQuantidadeAutomaticamente},model:{value:(_vm.quantidade),callback:function ($$v) {_vm.quantidade=$$v},expression:"quantidade"}}):_vm._e()]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.canAprovar)?_c('v-btn',{attrs:{"block":"","color":"primary","disabled":_vm.quantidadeSelecionada <= 0},on:{"click":_vm.aprovar}},[_c('v-icon',[_vm._v("mdi-thumb-up")]),_vm._v(" ("+_vm._s(_vm.quantidadeSelecionada)+")")],1):_vm._e()],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[(_vm.canRecusar)?_c('v-btn',{attrs:{"block":"","color":"error","disabled":_vm.quantidadeSelecionada <= 0},on:{"click":_vm.reprovar}},[_c('v-icon',[_vm._v("mdi-thumb-down")]),_vm._v(" ("+_vm._s(_vm.quantidadeSelecionada)+")")],1):_vm._e()],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[(_vm.canList)?_c('v-btn',{attrs:{"block":"","title":"Emitir Ticket de Solicitados","color":"secondary","disabled":_vm.solicitacoes.length == 0},on:{"click":_vm.visualizarTicketSolicitados}},[_c('v-icon',[_vm._v("mdi-ticket-confirmation-outline")]),_vm._v("Ticket")],1):_vm._e()],1)],1)]}}])})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.solicitacoes,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"header.acoes",fn:function(){return [(_vm.canUpdate)?_c('v-icon',{staticClass:"white--text",on:{"click":_vm.selecionarTodos}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e()]},proxy:true},{key:"item.acoes",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.public_id},model:{value:(_vm.selecionados),callback:function ($$v) {_vm.selecionados=$$v},expression:"selecionados"}})]}},{key:"item.triado_em",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toLocaleDateTimeString")(item.triado_em))+" ")])]}},{key:"item.created_by",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.created_by.name)+" ("+_vm._s(item.created_by.empresa)+")")])]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toLocaleDateTimeString")(item.created_at))+" ")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getStatus(item.status))+" ")])]}},{key:"item.numero_pedido",fn:function(ref){
            var item = ref.item;
return _vm._l((item.numeros_pedidos),function(numero,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(numero)+" ")])})}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)}),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.showTicketSolicitados),callback:function ($$v) {_vm.showTicketSolicitados=$$v},expression:"showTicketSolicitados"}},[_c('v-card',[_c('componente-ticket-solicitados')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }