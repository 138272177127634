var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseCard',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.modalChamarCaminhoes),callback:function ($$v) {_vm.modalChamarCaminhoes=$$v},expression:"modalChamarCaminhoes"}},[_c('modal-chamar-caminhoes',{ref:"modal",attrs:{"callback":_vm.chamarCaminhoes,"terminalDestino":_vm.terminalDestino,"quantidade":_vm.quantidadeSelecionada},on:{"update:terminalDestino":function($event){_vm.terminalDestino=$event},"update:terminal-destino":function($event){_vm.terminalDestino=$event}}})],1),_c('v-card',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-card-actions',{staticClass:"justify-end"},[_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"rules":{
              required: true,
              min_value: 0,
              max_value: _vm.emPatioValido.length,
            },"name":"quantidade"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [(_vm.canUpdate)?_c('v-text-field',{attrs:{"label":"Quantidade","type":"number","min":1,"max":_vm.emPatioValido.length,"error-messages":errors},on:{"input":_vm.selecionarQuantidadeAutomaticamente},model:{value:(_vm.quantidade),callback:function ($$v) {_vm.quantidade=$$v},expression:"quantidade"}}):_vm._e()]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.canUpdate)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","disabled":_vm.quantidadeSelecionada <= 0},on:{"click":_vm.showModal}},[_vm._v("Chamar ("+_vm._s(_vm.quantidadeSelecionada)+")")]):_vm._e()],1)],1)]}}])})],1),_c('v-card',[(_vm.canList)?_c('v-card-title',[_vm._v(" Filtro "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.orderedEmPatio},scopedSlots:_vm._u([{key:"header.acoes",fn:function(){return [(_vm.canList)?_c('v-icon',{staticClass:"white--text",on:{"click":_vm.selecionarTodos}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e()]},proxy:true},{key:"item.acoes",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 mb-2 menu",attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[[_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.handleVisualizarComprovanteLaudo(item)}}},[_vm._v("Laudo de Limpeza")])]],2)],1),(_vm.verificaDataCota(item) && _vm.verificaDataPedido(item))?_c('v-checkbox',{attrs:{"value":item.public_id},model:{value:(_vm.selecionados),callback:function ($$v) {_vm.selecionados=$$v},expression:"selecionados"}}):_vm._e()]}},{key:"item.senhas",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.senhas.length > 0 ? item.senhas[0].sequencia : '-')+" ")]}},{key:"item.embalagem",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.pedidos_agendados.length > 0 ? item.pedidos_agendados[0].pedido.embalagem.descricao : '-')+" ")]}},{key:"item.pedidos_agendados.quantidade",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"div-pedido-info"},_vm._l((item.pedidos_agendados),function(pedido,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(_vm.converteKg(pedido.quantidade_agendada))+" ")])}),0)]}},{key:"item.triado_em",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.triado_em ? new Date(item.triado_em).toLocaleString() : '-')+" ")]}},{key:"item.data_cota",fn:function(ref){
            var item = ref.item;
return [(item.data_cota)?_c('div',[(_vm.cota_dia_todo(item.data_cota))?_c('span',[_vm._v(_vm._s(_vm._f("iso2br")(item.data_cota.data_inicio)))]):(_vm.cota_mesmo_dia(item.data_cota))?_c('span',[_vm._v(" "+_vm._s(_vm.mostra_cota_mesmo_dia(item.data_cota)))]):_c('span',[_vm._v(" "+_vm._s(_vm._f("toBrazilianDateTime")(item.data_cota.data_inicio))+" - "+_vm._s(_vm._f("toBrazilianDateTime")(item.data_cota.data_fim))+" ")])]):_c('div',[_vm._v("Não definida")])]}},{key:"item.data_pedido",fn:function(ref){
            var item = ref.item;
return [(item.data_pedido)?_c('div',[(_vm.cota_mesmo_dia(item.data_pedido))?_c('span',[_vm._v(" "+_vm._s(_vm._f("iso2br")(item.data_pedido.data_inicio)))]):_c('span',[_vm._v(" "+_vm._s(_vm._f("iso2br")(item.data_pedido.data_inicio))+" - "+_vm._s(_vm._f("iso2br")(item.data_pedido.data_fim))+" ")])]):_c('div',[_vm._v("Não definida")])]}},{key:"item.numero_pedido",fn:function(ref){
            var item = ref.item;
return _vm._l((item.pedidos_agendados),function(pedido,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(pedido.pedido.numero_pedido)+" ")])})}},{key:"item.tempoEmPatio",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTempoEmPatio(item))+" ")]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)}),_c('download-excel',{attrs:{"name":"em-patio.xls","data":_vm.orderedEmPatio,"fields":_vm.fields_excel}},[_c('v-btn',{staticClass:"w-100 mt-5",staticStyle:{"margin-bottom":"20px"},attrs:{"color":"info"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":"","dark":""}},[_vm._v(" mdi-download ")]),_vm._v(" Baixar Planilha")],1)],1)],1),_c('v-dialog',{model:{value:(_vm.showComprovanteLaudo),callback:function ($$v) {_vm.showComprovanteLaudo=$$v},expression:"showComprovanteLaudo"}},[_c('v-card',[_c('comprovante-laudo',{attrs:{"itensLaudo":this.itensLaudo}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }