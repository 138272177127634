import { mapActions, mapMutations } from 'vuex'
import moment from 'moment'

export const methods = {
  ...mapMutations('agendamentos', ['ATUALIZA_AGENDAMENTO']),
  ...mapActions('solicitacaoBaixa', [
    'getAgendamentosEmPatio',
    'addSolicitacaoBaixa',
  ]),
  ...mapActions('template', ['successMessage', 'errorMessage']),
  ...mapActions('patio', [
    'getAgendamentoGerenciamentoServico',
    'getLaudoLimpezaAgendamento',
    'getItensLaudoCheckList',
  ]),
  cleanSelecionados() {
    this.selecionados.splice(0)
  },
  converteKg(item) {
    return (parseFloat(item) * 1000).toFixed(0)
  },
  formatDate(date) {
    if (!date) return null

    return moment(date).format('DD/MM/YYYY')
  },
  async getAgendamento(agendamento) {
    return await this.getAgendamentoGerenciamentoServico(agendamento)
  },

  async handleVisualizarComprovanteLaudo(item) {
    const agendamento = await this.getAgendamento(item)
    try {
      const laudo_limpeza = await this.getLaudoLimpezaAgendamento(agendamento)
      agendamento.laudo_limpeza = laudo_limpeza
      this.ATUALIZA_AGENDAMENTO(agendamento)
      this.visualizarComprovanteLaudo()
    } catch (e) {
      if (e.response) {
        this.errorMessage(e.response.data)
        return
      }
      if (e.message) {
        this.errorMessage(e.message)
        return
      }
      this.errorMessage(e)
    }
  },
  visualizarComprovanteLaudo() {
    this.showComprovanteLaudo = true
    this.getLaudoItensCheckList()
  },
  fecharComprovanteAgendamento() {
    this.showComprovanteLaudo = false
  },
  verificaDataCota(item) {
    const data_cota = moment(item.data_cota ? item.data_cota.data_inicio : null)
    const now = new Date()
    if (data_cota.isValid()) {
      if (moment(data_cota).isAfter(now)) {
        return false
      }
    }
    return true
  },
  verificaDataPedido(item) {
    const data_pedido = moment(
      item.data_pedido ? item.data_pedido.data_inicio : null
    )
    const now = new Date()
    if (data_pedido.isValid()) {
      if (moment(data_pedido).isAfter(now)) {
        return false
      }
    }
    return true
  },
  getTempoEmPatio(item) {
    let data = item.triado_em
    const data_cota = moment(item.data_cota ? item.data_cota.data_inicio : null)
    const triado_em = moment(item.triado_em)

    if (data_cota.isValid()) {
      if (moment(data_cota).isAfter(triado_em)) {
        data = item.data_cota ? item.data_cota.data_inicio : null
      }
    }

    if (data !== null) {
      const diferencaEmMs = new Date() - Date.parse(data)
      const tempo = diferencaEmMs / 1000
      const horas = Math.floor(tempo / (60 * 60))
      const minutos = Math.floor((tempo % (60 * 60)) / 60)
      if (minutos > 0) {
        return `${horas
          .toString()
          .padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`
      } else {
        return '00:00'
      }
    } else {
      return '00:00'
    }
  },
  async chamarCaminhoes() {
    try {
      const data = this.selecionados.map((triagem) => ({
        triagem,
        proprietario: this.empresaAtual.public_id,
        terminal_destino: this.terminalDestino,
      }))
      await this.addSolicitacaoBaixa(data)
      this.modalChamarCaminhoes = false
      this.quantidade = null
      this.selecionados = []
      this.$emit('atualizaResumo')
      this.$nextTick(() =>
        this.successMessage('Solicitação realizada com sucesso!')
      )
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
  showModal() {
    this.modalChamarCaminhoes = true
    this.$nextTick(() => this.$refs.modal.resetar())
  },
  selecionarQuantidadeAutomaticamente(n) {
    this.selecionados = this.emPatioValido.slice(0, n).map((o) => o.public_id)
  },
  getTempo(item) {
    const diferencaEmMs = new Date() - Date.parse(item)
    const segundos = diferencaEmMs / 1000
    return segundos
  },
  selecionarTodos() {
    if (this.quantidadeSelecionada === 0) {
      this.selecionados = this.emPatioValido.map((o) => o.public_id)
    } else {
      this.cleanSelecionados()
    }
  },
  async getItems(params) {
    try {
      this.cleanSelecionados()
      this.quantidade = null
      await this.getAgendamentosEmPatio(params)
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
  async getLaudoItensCheckList() {
    try {
      this.itensLaudo = []
      var itensLaudoCheckList = await this.getItensLaudoCheckList(
        this.agendamento
      )
      for (var i = 0; i < itensLaudoCheckList.length; i++) {
        var checkTrue = ''
        var checkFalse = ''
        var obj = {
          descricao: '',
          sim: '',
          nao: '',
          observacao: '',
        }

        if (itensLaudoCheckList[i].selectedItemKey == 1) {
          checkTrue = 'X'
        } else if (itensLaudoCheckList[i].selectedItemKey == 2) {
          checkFalse = 'X'
        }
        obj.descricao = itensLaudoCheckList[i].description
        obj.sim = checkTrue
        obj.nao = checkFalse
        obj.observacao = itensLaudoCheckList[i].observation
        this.itensLaudo.push(obj)
      }
      return this.itensLaudo
    } catch (e) {
      if (e.response) {
        this.errorMessage(e.response.data)
        return
      }
      if (e.message) {
        this.errorMessage(e.message)
        return
      }
      this.errorMessage(e)
    }
  },
}
