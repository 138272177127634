<template>
  <BaseCard>
    <v-card>
      <validation-observer v-slot="{}">
        <v-card-actions class="justify-end">
          <v-col cols="2">
            <validation-provider
              :rules="{
                required: true,
                min_value: 1,
                max_value: emTransito.length,
              }"
              name="quantidade"
              v-slot="{ errors }"
            >
              <v-text-field
                v-if="canUpdate"
                v-model="quantidade"
                label="Quantidade"
                type="number"
                :min="1"
                :max="emTransito.length"
                :error-messages="errors"
                @input="selecionarQuantidadeAutomaticamente"
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <v-btn
              v-if="canAprovar"
              block
              color="primary"
              @click="aprovar"
              :disabled="quantidadeSelecionada <= 0"
              ><v-icon>mdi-thumb-up</v-icon> ({{
                quantidadeSelecionada
              }})</v-btn
            >
          </v-col>
          <v-col cols="2" class="text-center">
            <v-btn
              v-if="canRecusar"
              block
              color="error"
              @click="reprovar"
              :disabled="quantidadeSelecionada <= 0"
              ><v-icon>mdi-thumb-down</v-icon> ({{
                quantidadeSelecionada
              }})</v-btn
            >
          </v-col>
        </v-card-actions>
      </validation-observer>
    </v-card>
    <v-data-table :headers="headers" :items="emTransito" class="elevation-1">
      <template v-slot:[`header.acoes`]>
        <v-icon v-if="canUpdate" @click="selecionarTodos" class="white--text">
          {{ icon }}
        </v-icon>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <v-checkbox :value="item.public_id" v-model="selecionados" />
      </template>
      <template #item.triado_em="{ item }">
        {{ item.triado_em | toLocaleDateTimeString }}
      </template>
      <template #item.created_at="{ item }">
        {{ item.created_at | toLocaleDateTimeString }}
      </template>
      <template #item.finished_at="{ item }">
        {{ item.finished_at | toLocaleDateTimeString }}
      </template>
      <template #item.saida_do_patio="{ item }">
        {{ item.saida_do_patio | toLocaleDateTimeString }}
      </template>
      <template #item.liberado_em="{ item }">
        {{ item.liberado_em | toLocaleDateTimeString }}
      </template>
      <template #item.created_by="{ item }">
        {{ item.created_by.name }} <br />
        {{ item.finished_at | toLocaleDateTimeString }}
      </template>
      <template v-slot:[`item.tempoEmPatio`]="{ item }">
        {{ getTempoEmPatio(item) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ getStatus(item.status) }} </span>
      </template>
      <template v-slot:[`item.numero_pedido`]="{ item }">
        <p v-for="(numero, index) in item.numeros_pedidos" :key="index">
          {{ numero }}
        </p>
      </template>
      <template v-slot:[`item.baixa_automatica`]="{ item }">
        <v-icon class="green--text">{{ item.baixa_automatica }}</v-icon>
      </template>
      <template v-slot:[`item.senhas`]="{ item }">
        {{ item.senhas.length ? item.senhas[0] : '-' }}
      </template>
      <template #no-data v-if="!canList">
        <span class="red--text"
          >Você não tem permissão para visualizar as informações desta
          tela</span
        >
      </template>
    </v-data-table>
  </BaseCard>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import prettyMilliseconds from 'pretty-ms'
import { toLocaleDateTimeString } from '@/mixins/convertion.js'
import { SolicitacaoBaixaStatus } from '@/utils/solicitacaoBaixa.js'

import {
  BasicPermissions,
  Sequences,
  CustomPermissions,
} from '@/utils/permissions'

export default {
  name: 'Solicitados',
  filters: { toLocaleDateTimeString },
  data() {
    return {
      headers: [
        {
          text: 'Confirmar Trânsito',
          divider: true,
          sortable: false,
          value: 'acoes',
          class: 'primary white--text',
        },
        {
          text: 'Senha',
          divider: true,
          value: 'senhas',
          class: 'primary white--text',
        },
        {
          text: 'Fornecedor',
          divider: true,
          value: 'fornecedor.business_name',
          class: 'primary white--text',
        },
        {
          text: 'Placa Cavalo',
          divider: true,
          value: 'placa',
          class: 'primary white--text',
        },
        {
          text: 'Modalidade',
          divider: true,
          value: 'modalidade',
          class: 'primary white--text',
        },
        {
          text: 'Motorista',
          divider: true,
          value: 'motorista',
          class: 'primary white--text',
        },
        {
          text: 'Sub Produto',
          divider: true,
          value: 'sub_produtos',
          class: 'primary white--text',
        },
        {
          text: 'Transportadora',
          divider: true,
          value: 'transportadora.business_name',
          class: 'primary white--text',
        },
        {
          text: 'Destinatario',
          divider: true,
          value: 'destinatario.business_name',
          class: 'primary white--text',
        },
        {
          text: 'Embalagem',
          divider: true,
          value: 'embalagens',
          class: 'primary white--text',
        },
        {
          text: 'Terminal Destino',
          divider: true,
          value: 'terminal_destino.contratado.business_name',
          class: 'primary white--text',
        },
        {
          text: 'Triado Em:',
          divider: true,
          value: 'triado_em',
          class: 'primary white--text',
        },
        {
          text: 'Número do Pedido',
          divider: true,
          value: 'numero_pedido',
          class: 'primary white--text',
        },
        {
          text: 'Chamado por:',
          divider: true,
          value: 'created_by',
          class: 'primary white--text',
        },
        // {
        //   text: 'Chamado Em:',
        //   divider: true,
        //   value: 'finished_at',
        //   class: 'primary white--text',
        // },
        {
          text: 'Liberado Em:',
          divider: true,
          value: 'liberado_em',
          class: 'primary white--text',
        },
        {
          text: 'Saída do Pátio:',
          divider: true,
          value: 'saida_do_patio',
          class: 'primary white--text',
        },
        {
          text: 'Tef:',
          divider: true,
          value: 'tempoEmPatio',
          class: 'primary white--text',
        },
        {
          text: 'Controle saída por:',
          divider: true,
          value: 'colaborador_em_tansito',
          class: 'primary white--text',
        },
      ],
      selecionados: [],
      interval: null,
      intervaloEmSegundos: 60,
      quantidade: null,
    }
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('solicitacaoBaixa', ['emTransito']),
    permission() {
      return Sequences.SolicitacaoBaixa.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
    canAprovar() {
      return this.$canDo(CustomPermissions.APROVAR_EMTRANSITO, this.permission)
    },
    canRecusar() {
      return this.$canDo(CustomPermissions.RECUSAR_EMTRANSITO, this.permission)
    },
    quantidadeSelecionada() {
      return this.selecionados.length
    },

    selecionouTodos() {
      return this.selecionados.length === this.emTransito.length
    },

    selecionouAlgum() {
      return this.selecionados.length > 0 && !this.selecionadoTodos
    },

    icon() {
      if (this.selecionouTodos) return 'mdi-close-box'
      if (this.selecionouAlgum) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },

  methods: {
    ...mapActions('solicitacaoBaixa', ['getEmTransito', 'confirmarTransito']),
    ...mapActions('template', ['successMessage', 'errorMessage']),

    cleanSelecionados() {
      this.selecionados.splice(0)
    },

    async aprovar() {
      await this.finalizar({
        proprietario: this.empresaAtual.public_id,
        emTransito: this.selecionados,
        aprovar: true,
      })
    },

    async reprovar() {
      await this.finalizar({
        proprietario: this.empresaAtual.public_id,
        emTransito: this.selecionados,
        aprovar: false,
      })
    },

    async finalizar(data) {
      try {
        await this.confirmarTransito(data)
        this.quantidade = null
        this.selecionados = []
        this.$emit('atualizaResumo')
        this.$nextTick(() =>
          this.successMessage('Operação realizada com sucesso!')
        )
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    selecionarQuantidadeAutomaticamente(n) {
      this.selecionados = this.emTransito.slice(0, n).map((o) => o.public_id)
    },

    selecionarTodos() {
      if (this.quantidadeSelecionada === 0) {
        this.selecionados = this.emTransito.map((o) => o.public_id)
      } else {
        this.cleanSelecionados()
      }
    },
    getTempoEmPatio(item) {
      if (item.triado_em !== null && item.saida_do_patio !== null) {
        const diferencaEmMs =
          Date.parse(item.saida_do_patio) - Date.parse(item.triado_em)
        return prettyMilliseconds(diferencaEmMs)
      } else {
        return '00:00:00'
      }
    },
    async getItems() {
      try {
        this.cleanSelecionados()
        this.quantidade = null
        await this.getEmTransito({
          proprietario: this.empresaAtual.public_id,
        })
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    getStatus(statusID) {
      return Object.values(SolicitacaoBaixaStatus).find(
        (o) => o.id === statusID
      ).name
    },
  },
  watch: {
    'emTransito.length': {
      handler() {
        this.$emit('update:totalEmTransito', String(this.emTransito.length))
      },
    },
  },
  async mounted() {
    if (this.canList) {
      await this.getItems()
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>
