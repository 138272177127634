<template>
  <v-container fluid>
    <v-overlay v-model="carregandoDados" />
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <resumo class="my-4" @getItems="getItems" ref="resumo" />
    <v-row>
      <v-col cols="12" sm="12">
        <v-tabs v-model="tab">
          <tab id="#emPatio" :total="totalEmPatio"> Em Patio </tab>
          <tab id="#solicitados" :total="totalSolicitados"> Solicitados </tab>
          <tab id="#liberados" :total="totalLiberados"> Liberados </tab>
          <tab id="#emTransito" :total="totalEmTransito"> Em Transito </tab>

          <v-tabs-items v-model="tab">
            <v-tab-item value="emPatio">
              <EmPatio
                ref="emPatio"
                :totalEmPatio.sync="totalEmPatio"
                @atualizaResumo="atualizaResumo"
              />
            </v-tab-item>
            <v-tab-item value="solicitados" eager>
              <Solicitados
                ref="solicitados"
                :totalSolicitados.sync="totalSolicitados"
                @atualizaResumo="atualizaResumo"
              />
            </v-tab-item>
            <v-tab-item value="liberados" eager>
              <Liberados
                ref="liberados"
                :totalLiberados.sync="totalLiberados"
                @atualizaResumo="atualizaResumo"
              />
            </v-tab-item>
            <v-tab-item value="emTransito" eager>
              <EmTransito
                ref="emTransito"
                :totalEmTransito.sync="totalEmTransito"
                @atualizaResumo="atualizaResumo"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EmPatio from '@/components/SolicitacaoBaixas/emPatio/Componente.vue'
import Solicitados from '@/components/SolicitacaoBaixas/solicitados/Componente.vue'
import Liberados from '@/components/SolicitacaoBaixas/liberados/Componente.vue'
import EmTransito from '@/components/SolicitacaoBaixas/emTransito/Componente.vue'
import Resumo from '@/components/SolicitacaoBaixas/resumo/Componente.vue'
import Tab from '@/components/SolicitacaoBaixas/Tab.vue'

export default {
  name: 'SolicitacaoBaixas',
  components: {
    EmPatio,
    Solicitados,
    Liberados,
    EmTransito,
    Resumo,
    Tab,
  },
  data() {
    return {
      page: {
        title: 'Solicitação de Baixas',
      },
      tab: 'emPatio',
      totalEmPatio: '0',
      totalSolicitados: '0',
      totalLiberados: '0',
      totalEmTransito: '0',
      breadcrumbs: [
        {
          text: 'Pátios',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Solicitação de Baixa',
          disabled: false,
        },
      ],
      carregandoDados: false,
    }
  },
  methods: {
    carregarDados() {
      this.carregandoDados = true
    },
    finalizarCarregamentoDados() {
      this.carregandoDados = false
    },
    async getItems(params) {
      this.carregarDados()
      await this.$refs[this.tab].getItems(params)
      this.finalizarCarregamentoDados()
    },
    async atualizaResumo() {
      await this.$refs.resumo.getItems()
    },
  },
}
</script>
