<template>
  <v-card>
    <v-card-title> Resumo </v-card-title>
    <v-card-text>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
      <v-data-table
        :headers="filteredHeaders"
        :items="items"
        class="elevation-1"
      >
        <template v-slot:[`item.total_peso`]="{ item }">
          {{ Number(item.total_peso).toFixed(2) }}
        </template>
        <template v-slot:[`item.data_chegada`]="{ item }">
          {{ item.data_chegada | toLocaleDateTimeString }}
        </template>
        <template v-slot:[`item.data_cota`]="{ item }">
          <div v-if="item.data_cota">
            <span v-if="cota_dia_todo(item.data_cota)">{{
              item.data_cota.data_inicio | iso2br
            }}</span>
            <span v-else-if="cota_mesmo_dia(item.data_cota)">
              {{ mostra_cota_mesmo_dia(item.data_cota) }}</span
            >
            <span v-else>
              {{ item.data_cota.data_inicio | toBrazilianDateTime }}
              -
              {{ item.data_cota.data_fim | toBrazilianDateTime }}
            </span>
          </div>
          <div v-else>Não definida</div>
        </template>
        <template v-slot:[`item.data_pedido`]="{ item }">
          {{ formatDate(item.data_pedido) }}
        </template>
        <template v-slot:[`item.tempoEmPatio`]="{ item }">
          {{ getTempoEmPatio(item) }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            v-if="canList"
            @click="callGetItems(item)"
            color="secondary"
            title="Visualizar itens dessa fila"
          >
            <v-icon>mdi-database-search</v-icon>
          </v-btn>
        </template>
        <template #no-data v-if="!canList">
          <span class="red--text"
            >Você não tem permissão para visualizar as informações desta
            tela</span
          >
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import contratoAPI from '@/services/tenants/contratos'
import Perfil from '@/utils/perfil'
import {
  cota_dia_todo,
  cota_mesmo_dia,
  mostra_cota_mesmo_dia,
} from '@/mixins/cotas.js'
import {
  toLocaleDateTimeString,
  iso2br,
  toBrazilianDateTime,
} from '@/mixins/convertion'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import moment from 'moment'

export default {
  name: 'ResumoSolicitacaoBaixa',
  mixins: [cota_dia_todo, cota_mesmo_dia, mostra_cota_mesmo_dia],
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Fornecedor',
          divider: true,
          value: 'fornecedor__name',
          class: 'primary white--text',
        },
        {
          text: 'Terminal',
          divider: true,
          value: 'terminal__name',
          class: 'primary white--text',
        },
        {
          text: 'Sub Produto',
          divider: true,
          value: 'sub_produto__name',
          class: 'primary white--text',
        },
        {
          text: 'Embalagem',
          divider: true,
          value: 'embalagem__name',
          class: 'primary white--text',
        },
        {
          text: 'Veículos',
          divider: true,
          value: 'total_veiculos',
          class: 'primary white--text',
        },
        {
          text: 'Peso',
          divider: true,
          value: 'total_peso',
          class: 'primary white--text',
        },
        {
          text: 'Data Chegada',
          divider: true,
          value: 'data_chegada',
          class: 'primary white--text',
        },
        {
          text: 'Data Cota',
          divider: true,
          value: 'data_cota',
          class: 'primary white--text',
        },
        // {
        //   text: 'Num. Pedido',
        //   divider: true,
        //   value: 'num_pedido',
        //   class: 'primary white--text',
        // },
        {
          text: 'Data Pedido',
          divider: true,
          value: 'data_pedido',
          class: 'primary white--text',
        },
        {
          text: 'Próxima Senha',
          divider: true,
          value: 'proxima_senha',
          class: 'primary white--text',
        },
        {
          text: 'Tempo em Patio',
          divider: true,
          value: 'tempoEmPatio',
          class: 'primary white--text',
        },
        {
          text: 'Ações',
          divider: true,
          value: 'action',
          class: 'primary white--text',
        },
      ],
      items: [],
      interval: null,
      intervaloEmSegundos: 60,
    }
  },
  filters: {
    toLocaleDateTimeString,
    iso2br,
    toBrazilianDateTime,
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),

    ehFornecedor() {
      return this.empresaAtual.tiposContrato.includes(Perfil.Fornecedor)
    },

    ehGate() {
      return (
        this.empresaAtual.tiposContrato.includes(Perfil.Unidade) &&
        this.empresaAtual.gate
      )
    },

    filteredHeaders() {
      return this.headers
    },
    permission() {
      return Sequences.SolicitacaoBaixa.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  methods: {
    ...mapActions('solicitacaoBaixa', [
      'getResumoSolicitacaoBaixa',
      'getResumoExtraFields',
    ]),
    ...mapActions('template', ['errorMessage']),
    ...mapMutations('solicitacaoBaixa', ['SET_TERMINAIS']),
    formatDate(date) {
      if (!date) return null

      return moment(date).format('DD/MM/YYYY')
    },
    getTempoEmPatio(item) {
      let data = item.data_chegada
      const data_cota = moment(
        item.data_cota ? item.data_cota.data_inicio : null
      )
      const data_chegada = moment(item.data_chegada)

      if (data_cota.isValid()) {
        if (moment(data_cota).isAfter(data_chegada)) {
          data = item.data_cota ? item.data_cota.data_inicio : null
        }
      }

      if (data !== null) {
        const diferencaEmMs = new Date() - Date.parse(data)
        const tempo = diferencaEmMs / 1000
        const horas = Math.floor(tempo / (60 * 60))
        const minutos = Math.floor((tempo % (60 * 60)) / 60)
        console.log(data)
        if (minutos > 0) {
          return `${horas
            .toString()
            .padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`
        } else {
          return '00:00'
        }
      } else {
        return '00:00'
      }
    },

    async callGetItems(item) {
      // console.log(this.items)
      // console.log(item)
      // let itens = this.items.filter((i) => i.produto__id === item.produto__id)
      // console.log(itens)
      this.$emit('getItems', {
        owner: item.fornecedor__public_id,
        fornecedor: item.fornecedor__id,
        terminal: item.terminal__id,
        produto: item.produto__id,
        sub_produto: item.sub_produto__id,
        embalagem: item.embalagem__id,
      })

      await this.setTerminais(item.fornecedor__public_id, item.gate__public_id)
    },

    async setTerminais(tenantPublicID, gatePublicID) {
      try {
        if (this.ehFornecedor || this.ehGate) {
          this.terminais = await contratoAPI.getContratos(tenantPublicID, {
            perfil: Perfil.Terminal,
          })

          const terminais_gate = await contratoAPI.getContratos(gatePublicID, {
            perfil: Perfil.Terminal,
          })

          const terminais_map = this.terminais.map((item) => item.cliente_id)

          this.terminais = [].concat(
            this.terminais,
            terminais_gate.filter(
              (terminal) => !terminais_map.includes(terminal.cliente_id)
            )
          )
        } else {
          const retorno = await contratoAPI.getContratos(tenantPublicID, {
            perfil: Perfil.Terminal,
          })

          this.terminais = retorno.filter(
            (terminal) => terminal.cliente_id === this.empresaAtual.public_id
          )
        }

        this.SET_TERMINAIS(this.terminais)
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    async getItems() {
      try {
        this.loading = true
        let sub_produto = []

        if (this.empresaAtual.grupo.sub_produtos.length > 0) {
          sub_produto = this.empresaAtual.grupo.sub_produtos.join(',')
        }

        const data = await this.getResumoSolicitacaoBaixa({
          proprietario: this.empresaAtual.public_id,
          sub_produto: sub_produto,
        })

        this.items = await Promise.all(
          data.map(async (resumo) => {
            const extraFields = await this.getResumoExtraFields({
              empresa: this.empresaAtual.public_id,
              proprietario: resumo.fornecedor__public_id,
              fornecedor: resumo.fornecedor__id,
              terminal: resumo.terminal__id,
              produto: resumo.produto__id,
              sub_produto: resumo.sub_produto__id,
              embalagem: resumo.embalagem__id,
            })

            return {
              ...resumo,
              ...extraFields,
            }
          })
        )
      } catch (e) {
        this.errorMessage(e.response.data)
      } finally {
        this.loading = false
      }
    },

    negarAcesso() {
      this.errorMessage(
        'Você não possui autorização para visualizar esta tela. Por gentileza, saia!'
      )
    },
  },
  async created() {
    if (this.canList) {
      await this.getItems()
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>
