<template>
  <BaseCard>
    <v-dialog v-model="showModalReprovar" max-width="500px">
      <v-card>
        <validation-observer v-slot="{ invalid }">
          <v-card-title> Confirmação </v-card-title>
          <v-card-text>
            <validation-provider name="observacao" rules="required">
              <v-text-field v-model="observacao" label="Motivo" />
            </validation-provider>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              v-if="canUpdate"
              color="error"
              @click="confirmReprovar"
              :disabled="invalid"
              >Reprovar</v-btn
            >
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
    <v-card>
      <validation-observer v-slot="{}">
        <v-card-actions class="justify-end">
          <v-col cols="2">
            <validation-provider
              :rules="{
                required: true,
                min_value: 1,
                max_value: solicitacoes.length,
              }"
              name="quantidade"
              v-slot="{ errors }"
            >
              <v-text-field
                v-if="canUpdate"
                v-model="quantidade"
                label="Quantidade"
                type="number"
                :min="1"
                :max="solicitacoes.length"
                :error-messages="errors"
                @input="selecionarQuantidadeAutomaticamente"
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <v-btn
              v-if="canAprovar"
              block
              color="primary"
              @click="aprovar"
              :disabled="quantidadeSelecionada <= 0"
              ><v-icon>mdi-thumb-up</v-icon> ({{
                quantidadeSelecionada
              }})</v-btn
            >
          </v-col>
          <v-col cols="2" class="text-center">
            <v-btn
              v-if="canRecusar"
              block
              color="error"
              @click="reprovar"
              :disabled="quantidadeSelecionada <= 0"
              ><v-icon>mdi-thumb-down</v-icon> ({{
                quantidadeSelecionada
              }})</v-btn
            >
          </v-col>
          <v-col cols="2" class="text-center">
            <v-btn
              v-if="canList"
              block
              title="Emitir Ticket de Solicitados"
              color="secondary"
              @click="visualizarTicketSolicitados"
              :disabled="solicitacoes.length == 0"
              ><v-icon>mdi-ticket-confirmation-outline</v-icon>Ticket</v-btn
            >
          </v-col>
        </v-card-actions>
      </validation-observer>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="solicitacoes"
      class="elevation-1"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:[`header.acoes`]>
        <v-icon v-if="canUpdate" @click="selecionarTodos" class="white--text">
          {{ icon }}
        </v-icon>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <v-checkbox :value="item.public_id" v-model="selecionados" />
      </template>
      <template v-slot:[`item.triado_em`]="{ item }">
        <span>{{ item.triado_em | toLocaleDateTimeString }} </span>
      </template>
      <template v-slot:[`item.created_by`]="{ item }">
        <span>{{ item.created_by.name }} ({{ item.created_by.empresa }})</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span>{{ item.created_at | toLocaleDateTimeString }} </span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ getStatus(item.status) }} </span>
      </template>
      <template v-slot:[`item.numero_pedido`]="{ item }">
        <p v-for="(numero, index) in item.numeros_pedidos" :key="index">
          {{ numero }}
        </p>
      </template>
      <template #no-data v-if="!canList">
        <span class="red--text"
          >Você não tem permissão para visualizar as informações desta
          tela</span
        >
      </template>
    </v-data-table>
    <v-dialog v-model="showTicketSolicitados" max-width="700px">
      <v-card>
        <componente-ticket-solicitados />
      </v-card>
    </v-dialog>
  </BaseCard>
</template>

<script>
import ComponenteTicketSolicitados from '@/components/SolicitacaoBaixas/solicitados/TicketSolicitados/Componente.vue'

import { mapActions, mapState } from 'vuex'

import { toLocaleDateTimeString } from '@/mixins/convertion.js'
import { SolicitacaoBaixaStatus } from '@/utils/solicitacaoBaixa.js'
import {
  BasicPermissions,
  Sequences,
  CustomPermissions,
} from '@/utils/permissions'

export default {
  name: 'Solicitados',
  filters: { toLocaleDateTimeString },
  components: {
    ComponenteTicketSolicitados,
  },
  provide() {
    return {
      fecharTicketSolicitados: this.fecharTicketSolicitados,
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Confirmar Trânsito',
          divider: true,
          sortable: false,
          value: 'acoes',
          class: 'primary white--text',
        },
        {
          text: 'Senha',
          divider: true,
          value: 'senhas',
          class: 'primary white--text',
        },
        {
          text: 'Placa Cavalo',
          divider: true,
          value: 'placa',
          class: 'primary white--text',
        },
        {
          text: 'Motorista',
          divider: true,
          value: 'motorista',
          class: 'primary white--text',
        },

        {
          text: 'Fornecedor',
          divider: true,
          value: 'fornecedor.business_name',
          class: 'primary white--text',
        },
        {
          text: 'Sub Produto',
          divider: true,
          value: 'sub_produtos',
          class: 'primary white--text',
        },
        {
          text: 'Embalagem',
          divider: true,
          value: 'embalagens',
          class: 'primary white--text',
        },
        {
          text: 'Modalidade',
          divider: true,
          value: 'modalidade',
          class: 'primary white--text',
        },
        {
          text: 'Triado Em',
          divider: true,
          value: 'triado_em',
          class: 'primary white--text',
        },
        {
          text: 'Número do Pedido',
          divider: true,
          value: 'numero_pedido',
          class: 'primary white--text',
        },
        {
          text: 'Transportadora',
          divider: true,
          value: 'transportadora.business_name',
          class: 'primary white--text',
        },
        {
          text: 'Destinatario',
          divider: true,
          value: 'destinatario.business_name',
          class: 'primary white--text',
        },
        {
          text: 'Terminal Destino',
          divider: true,
          value: 'terminal_destino.contratado.business_name',
          class: 'primary white--text',
        },
        {
          text: 'Solicitante',
          divider: true,
          value: 'created_by',
          class: 'primary white--text',
        },
        {
          text: 'Data/Hora',
          divider: true,
          value: 'created_at',
          class: 'primary white--text',
        },
        {
          text: 'Status',
          divider: true,
          value: 'status',
          class: 'primary white--text',
        },
      ],
      selecionados: [],
      interval: null,
      intervaloEmSegundos: 60,
      showModalReprovar: false,
      observacao: null,
      quantidade: null,
      showTicketSolicitados: false,
    }
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('solicitacaoBaixa', ['solicitacoes']),

    quantidadeSelecionada() {
      return this.selecionados.length
    },

    selecionouTodos() {
      return this.selecionados.length === this.solicitacoes.length
    },

    selecionouAlgum() {
      return this.selecionados.length > 0 && !this.selecionadoTodos
    },

    icon() {
      if (this.selecionouTodos) return 'mdi-close-box'
      if (this.selecionouAlgum) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    permission() {
      return Sequences.SolicitacaoBaixa.toString()
    },
    canAprovar() {
      return this.$canDo(CustomPermissions.APROVAR_SOLICITADOS, this.permission)
    },
    canRecusar() {
      return this.$canDo(CustomPermissions.RECUSAR_SOLICITADOS, this.permission)
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },

  methods: {
    ...mapActions('solicitacaoBaixa', [
      'getSolicitacoesBaixa',
      'finalizarSolicitacaoBaixa',
    ]),
    ...mapActions('template', ['successMessage', 'errorMessage']),

    cleanSelecionados() {
      this.selecionados.splice(0)
    },

    visualizarTicketSolicitados() {
      this.showTicketSolicitados = true
    },

    fecharTicketSolicitados() {
      this.showTicketSolicitados = false
      this.$router.push({
        name: 'Solicitação de Baixas',
      })
    },

    async aprovar() {
      await this.finalizar({
        proprietario: this.empresaAtual.public_id,
        solicitacoes: this.selecionados,
        aprovar: true,
      })
    },

    reprovar() {
      this.showModalReprovar = true
    },

    async confirmReprovar() {
      if (this.observacao)
        await this.finalizar({
          proprietario: this.empresaAtual.public_id,
          solicitacoes: this.selecionados,
          aprovar: false,
          observacao: this.observacao,
        })
      this.showModalReprovar = false
      this.observacao = null
      this.$emit('atualizaResumo')
    },

    async finalizar(data) {
      try {
        await this.finalizarSolicitacaoBaixa(data)
        this.quantidade = null
        this.selecionados = []
        this.$nextTick(() =>
          this.successMessage('Operação realizada com sucesso!')
        )
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    showModal() {
      this.modalChamarCaminhoes = true
      this.$nextTick(() => this.$refs.modal.resetar())
    },

    selecionarQuantidadeAutomaticamente(n) {
      this.selecionados = this.solicitacoes.slice(0, n).map((o) => o.public_id)
    },

    selecionarTodos() {
      if (this.quantidadeSelecionada === 0) {
        this.selecionados = this.solicitacoes.map((o) => o.public_id)
      } else {
        this.cleanSelecionados()
      }
    },

    async getItems() {
      try {
        this.cleanSelecionados()
        this.quantidade = null
        await this.getSolicitacoesBaixa({
          proprietario: this.empresaAtual.public_id,
        })
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    getStatus(statusID) {
      return Object.values(SolicitacaoBaixaStatus).find(
        (o) => o.id === statusID
      ).name
    },
  },
  watch: {
    'solicitacoes.length': {
      handler() {
        this.$emit('update:totalSolicitados', String(this.solicitacoes.length))
      },
    },
  },
  async mounted() {
    if (this.canList) {
      await this.getItems()
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>
