<template>
  <v-container class="container-comprovante text-center text-subtitle-2">
    <v-row id="actions">
      <v-col class="d-flex pa-8">
        <v-btn type="button" color="primary" @click="fecharTicketSolicitados"
          >Fechar</v-btn
        >
        <v-spacer />
        <v-btn color="secondary" @click="imprimirComprovante">Imprimir</v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-space-between" id="logos">
      <v-col cols="4">
        <v-img
          class="mx-auto"
          contain
          height="100"
          width="150"
          src="@/assets/images/icon/logo.png"
        />
      </v-col>
      <v-col cols="4">
        <v-img
          class="mx-auto"
          contain
          height="100"
          width="150"
          src="@/assets/images/icon/logo.png"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1>Ticket de Solicitados</h1>
      </v-col>
    </v-row>
   
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="solicitacoes"
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ item.created_at | toBrazilianDateTime }} </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { toReal } from '@/mixins/convertion.js'

import { data } from './_data'
import { computed } from './_computed'
import { filters } from './_filters'
import { methods } from './_methods'


export default {
  name: 'ComponenteTicketSolicitados',
  inject: ['fecharTicketSolicitados'],
  mixins: [toReal],
  data,
  computed,
  filters,
  methods,
}
</script>

<style>
.container-comprovante p {
  text-transform: uppercase;
  line-height: 5px;
}

strong::before {
  content: ' ';
}

.label::after {
  content: ':';
}

@media print {
  .v-application--wrap,
  .v-dialog button {
    display: none;
  }

  .v-dialog {
    display: block;
    height: 100vh;
    width: 100vw;
    margin: 0;
  }

  .v-card {
    height: 100%;
    width: 100%;
  }

  #actions {
    display: none;
  }

  #logos {
    margin-top: -20px;
  }

  @page {
    margin: 0;
  }
}
</style>
