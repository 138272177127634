<template>
  <v-tab :href="id">
    <v-badge
      bordered
      color="error"
      overlap
      offset-y="5"
      offset-x="3"
      :content="total"
    >
      <slot name="default" />
    </v-badge>
  </v-tab>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    id: {
      type: String,
      required: true,
    },
    total: {
      type: String,
      required: true,
    },
  },
}
</script>
