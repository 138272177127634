export function data() {
    return {
        headers: [
            {
              text: 'Sequência',
              divider: true,
              sortable: false,
              value: 'senhas',
            },
            {
              text: 'Placa',
              divider: true,
              sortable: false,
              value: 'placa',
            },
            {
              text: 'Sub Produto',
              divider: true,
              sortable: false,
              value: 'sub_produtos',
            },
            {
              text: 'Terminal Destino',
              divider: true,
              sortable: false,
              value: 'terminal_destino.contratado.business_name',
            },
            {
              text: 'Horário Chamada',
              divider: true,
              sortable: false,
              value: 'created_at',
            },
          ],
    }
  }
  