import { mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export const computed = {
  ...mapState('auth', ['empresaAtual']),
  ...mapState('solicitacaoBaixa', ['emPatio']),
  ...mapState('agendamentos', ['agendamento']),

  emPatioValido() {
    return this.emPatio.filter(
      (item) => this.verificaDataCota(item) && this.verificaDataPedido(item)
    )
  },

  orderedEmPatio() {
    return this.emPatio.sort((a, b) =>
      this.getTempo(a.triado_em) > this.getTempo(b.triado_em) ? -1 : 1
    )
  },
  quantidadeSelecionada() {
    return this.selecionados.length
  },
  selecionouTodos() {
    return this.selecionados.length === this.emPatio.length
  },
  selecionouAlgum() {
    return this.selecionados.length > 0 && !this.selecionadoTodos
  },
  icon() {
    if (this.selecionouTodos) return 'mdi-close-box'
    if (this.selecionouAlgum) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  },
  permission() {
    return Sequences.SolicitacaoBaixa.toString()
  },
  canList() {
    return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
  },
  canCreate() {
    return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
  },
  canUpdate() {
    return this.$canDo(BasicPermissions.EDITAR, this.permission)
  },
  canDelete() {
    return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
  },
}
