var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Resumo ")]),_c('v-card-text',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.filteredHeaders,"items":_vm.items},scopedSlots:_vm._u([{key:"item.total_peso",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.total_peso).toFixed(2))+" ")]}},{key:"item.data_chegada",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(item.data_chegada))+" ")]}},{key:"item.data_cota",fn:function(ref){
var item = ref.item;
return [(item.data_cota)?_c('div',[(_vm.cota_dia_todo(item.data_cota))?_c('span',[_vm._v(_vm._s(_vm._f("iso2br")(item.data_cota.data_inicio)))]):(_vm.cota_mesmo_dia(item.data_cota))?_c('span',[_vm._v(" "+_vm._s(_vm.mostra_cota_mesmo_dia(item.data_cota)))]):_c('span',[_vm._v(" "+_vm._s(_vm._f("toBrazilianDateTime")(item.data_cota.data_inicio))+" - "+_vm._s(_vm._f("toBrazilianDateTime")(item.data_cota.data_fim))+" ")])]):_c('div',[_vm._v("Não definida")])]}},{key:"item.data_pedido",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.data_pedido))+" ")]}},{key:"item.tempoEmPatio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTempoEmPatio(item))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.canList)?_c('v-btn',{attrs:{"color":"secondary","title":"Visualizar itens dessa fila"},on:{"click":function($event){return _vm.callGetItems(item)}}},[_c('v-icon',[_vm._v("mdi-database-search")])],1):_vm._e()]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }